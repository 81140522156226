import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalProduitReservation from '~/components/List/Modal/Produit/Reservation';
import Media from '~/components/Media';
import Tag from '~/components/Tag';
import { ListItemProps } from '~/containers/Espace/List/props';
import displayCurrency from '~/utils/displayCurrency';

import ListItem from './index';

const ListItemProduit: FC<ListItemProps<ProduitType, ProduitData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const { t } = useTranslation();
  const [reservationOpen, setReservationOpen] = useState<boolean>(false);
  const [doc, setDoc] = useState<DocumentType<ProduitType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  // const handleReservationOnClick = (
  //   event: SyntheticEvent<HTMLButtonElement>,
  // ) => {
  //   event.preventDefault();
  //   setReservationOpen(true);
  // };

  const handleReservationCloseOnClick = () => {
    setReservationOpen(false);
  };

  if (!doc) {
    return null;
  }

  let media;

  if (doc.medias && doc.medias.length > 0) {
    [media] = doc.medias.filter(m =>
      ['video/', 'image/'].includes(m.type?.slice(0, 6)),
    );
  }

  // const image =
  //   media &&
  //   getMediaImageData({
  //     media,
  //     maxWidth: 84,
  //     transformations: ['c_contain', 'b_ffffff', 'r_1'],
  //   });

  return (
    <>
      {reservationOpen && (
        <ModalProduitReservation
          closeOnClick={handleReservationCloseOnClick}
          espaceId={model.espaceId}
          produit={doc}
        />
      )}
      <ListItem
        // actions={compact([
        //   (!doc.hasInventory || doc.qtyAvailable > (doc.qtyReserved ?? 0)) && {
        //     icon: IconCalendar,
        //     label: 'Réserver',
        //     onClick: handleReservationOnClick,
        //   },
        // ])}
        // changePosition={changePosition}
        displayActionId={false}
        doc={doc}
        icon={
          media && (
            <Media
              media={media}
              options={{
                crop: 'contain',
                height: 42,
                width: 42,
              }}
              thumbnail
            />
          )
        }
        index={index}
        label={doc.label}
        onClick={onClick}
      >
        <div className="mt-1 flex items-center space-x-3">
          {!doc.hasInventory && <Tag state="info">Sans inventaire</Tag>}
          {doc.hasInventory && (
            <>
              {doc.qtyAvailable > 0 &&
                (undefined === doc.qtyReserved ||
                  doc.qtyReserved < doc.qtyAvailable) && (
                  <Tag state="success">
                    {t('product.inStock', { count: doc.qtyAvailable })}
                  </Tag>
                )}
              {0 === doc.qtyAvailable && (
                <Tag state="danger">Rupture de stock</Tag>
              )}
            </>
          )}
          {undefined !== doc.qtyReserved && doc.qtyReserved > 0 && (
            <Tag state="warning">
              {t('product.reservation', { count: doc.qtyReserved })}
            </Tag>
          )}
          {doc.hasVariants && (
            <Tag>{t('product.variants', { count: doc.nbChildren })}</Tag>
          )}
          {!doc.hasGlobalPricing && undefined !== doc.price && doc.price > 0 && (
            <Tag className="flex items-center space-x-1.5">
              {(doc.hasSlots || doc.hasOptions) && (
                <span className="text-xs">à partir de</span>
              )}
              <span>
                {doc.price} {displayCurrency(doc.priceCurrency)}
              </span>
            </Tag>
          )}
        </div>
      </ListItem>
    </>
  );
};

export default ListItemProduit;
