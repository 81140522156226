import {
  DocumentType,
  ProduitType,
  ReservationType,
} from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { Form } from 'packages/formidable';
import { ReservationData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useState } from 'react';

import Modal, { ViewModalProps } from '~/components/View/Modal';
import Variants from '~/containers/Variants';
import reservation from '~/params/produit/reservation.json';

import Media from '../../../Media';

interface ModalProduitReservationProps extends Omit<ViewModalProps, 'title'> {
  espaceId: string;
  produit: DocumentType<ProduitType>;
}
const ModalProduitReservation: FC<ModalProduitReservationProps> = ({
  espaceId,
  closeOnClick,
  produit,
}) => {
  const [variantId, setVariantId] = useState<string>();

  const handleOnClick = async (values?: any): Promise<void> => {
    if (espaceId) {
      if (produit.hasVariants && !variantId) {
        alert('Veuillez saisir la déclinaison');
      } else {
        const reservationData = new ReservationData({
          espaceId,
        });

        await reservationData.create({
          ...values,
          espaceId,
          expiredAt: values.hasExpirationDate
            ? dayjs(values.expiratedAt).toISOString()
            : undefined,
          priceId: values.price,
          productId: variantId ?? produit.id,
          quantity: 1,
        } as ReservationType);

        if (closeOnClick) {
          closeOnClick();
        }
      }
    }
  };

  const handleVariantOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.currentTarget;
    setVariantId(value);
  };

  return (
    <Modal closeOnClick={closeOnClick} title="Réservation">
      <div className="my-6 rounded bg-light-500 p-6 ">
        <div className="flex space-x-6">
          {produit.medias && produit.medias.length > 0 && (
            <Media
              media={produit.medias[0]}
              options={{
                background: '#ffffff',
                crop: 'contain',
                height: 100,
                width: 100,
              }}
            />
          )}
          <div className="flex-1">
            <h2 className="font-bold">{produit.label}</h2>
            {!produit.hasVariants &&
              produit.hasInventory &&
              produit.qtyAvailable > 0 && (
                <span>
                  Ce produit possède {produit.qtyAvailable} pièces en stock
                </span>
              )}
            {!produit.hasVariants && !produit.hasInventory && (
              <span>Ce produit ne suit pas l&apos;inventaire</span>
            )}
            {produit.hasVariants && (
              <span>Ce produit possède des déclinaisons</span>
            )}
          </div>
          {produit.hasVariants && (
            <div>
              <Variants onChange={handleVariantOnChange} produit={produit} />
            </div>
          )}
        </div>
      </div>
      <Form
        datas={reservation}
        footerProps={{
          className: 'mt-6 flex justify-end',
        }}
        name="reservation"
        onSubmit={handleOnClick}
        params={{
          collectionName: 'produits',
          docId: produit.id,
          ...produit,
        }}
        submitProps={{
          label: 'Enregistrer la réservation',
        }}
        title="Réservation"
      />
    </Modal>
  );
};

export default ModalProduitReservation;
