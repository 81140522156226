import { DocumentType, ProduitType } from '@innedit/innedit-type';
import { ProduitData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

const Variants: FC<{
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  produit: DocumentType<ProduitType>;
}> = ({ onChange, produit }) => {
  const [variants, setVariants] = useState<DocumentType<ProduitType>[]>();

  useEffect(() => {
    const productData = new ProduitData({
      espaceId: produit.espaceId,
      wheres: {
        kind: 'variant',
        parent: produit.id,
      },
    });

    productData.watch(docs => setVariants(docs));
  }, [produit.espaceId, produit.id]);

  if (!variants) {
    return null;
  }

  return (
    <div>
      {variants.map(variant => (
        <div key={variant.id} className="flex justify-end space-x-3">
          <input
            name="variant-id"
            onChange={onChange}
            type="radio"
            value={variant.id}
          />
          <strong>{variant.label}</strong>
          <span>{variant.qtyAvailable}</span>
        </div>
      ))}
    </div>
  );
};

export default Variants;
